<template>
  <div :class="['video-wrapper flex-col cntr', videoContainerClasses]" :style="backgroundColorStyle">
    <div v-if="content" :class="['video-content-wrapper', { 'black-bg': isBlackBg }]">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="content" />
    </div>
    <!-- use metadata (first frame of video) instead of poster because poster can't be resized, resulting is large (800k image)-->
    <!-- :poster="videoPoster" -->
    <div class="video-container">
      <video
        ref="videoRef"
        :preload="preload ? 'auto' : 'metadata'"
        v-bind="videoPlayStyle"
        :poster="videoPosterUrl"
        :width="width"
        :height="height"
        :class="videoClasses"
        :data-in-view="is_in_view"
        :data-is-loaded="is_loaded"
        :data-is-playing="isPlaying"
      >
        <template v-if="videoType === 'cloudinary'">
          <source :src="videoUrl" />
        </template>
        <template v-if="videoType === 'cms'">
          <source v-for="(format, key) in videoObject.formats" :key="key" :src="format.url" :type="(key as string)" />
        </template>
      </video>
      <!-- <button ref="playButton" class="play-button" @click="playButtonClick()" /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
/* eslint-disable vue/prop-name-casing */
import { VideoHTMLAttributes } from 'nuxt/dist/app/compat/capi';
import { CloudinaryAsset, File } from '@solo-stove/types/contentstack';
import { useCloudinary, VideoOptions } from '~/composables/cloudinary';
import { getFirstIndex } from '~/util/arrays';

const cloudinary = useCloudinary();

const props = defineProps({
  title: {
    type: String,
    default: '',
    required: false,
  },
  content: {
    type: String,
    required: false,
    default: '',
  },
  cms_asset: {
    type: [
      Object as () => {
        assetConnection: {
          edges: Array<{ node: File }>;
        };
        posterConnection: {
          edges: Array<{ node: File }>;
        };
      },
      null,
    ],
  },
  asset: {
    type: Object as () => CloudinaryAsset,
    required: false,
    default: undefined,
  },
  background_color: {
    type: Object as () => {
      color: string;
    },
    required: false,
    default: () => {
      return {
        color: '',
      };
    },
  },
  display_as_gif: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object as () => VideoOptions | undefined,
    default: undefined,
  },
  videoClassesOverride: {
    type: Array as () => (string | Record<string, boolean>)[],
    default: () => [],
  },
  enableLogging: {
    type: Boolean,
    default: false,
  },
  is_in_view: {
    type: Boolean,
    default: false,
    required: false,
  },
  is_loaded: {
    type: Boolean,
    default: false,
  },
  reset_on_scrollout: {
    type: Boolean,
    default: false,
  },
  pause_on_scrollout: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: true,
  },
  videoContainerClasses: {
    type: Array as () => (string | Record<string, boolean>)[],
    default: () => [],
  },
});

const videoRef = ref<HTMLVideoElement | null>(null);
// const playButton = ref<HTMLButtonElement | null>(null);
const isPlaying = ref(false);

const publicId = computed<string | undefined>(
  () => props.asset?.public_id || getFirstIndex(props.asset as any).public_id
);
const backgroundColorStyle = computed<string | undefined>(() => {
  return props.background_color?.color ? `background-color: ${props.background_color.color}` : undefined;
});
// const videoPoster = computed<string | undefined>(() => cloudinary.generateImageUrl(video.value?.public_id));
const videoPosterUrl = computed<string | undefined>(() => {
  if (videoType === 'cloudinary') {
    return publicId.value
      ? cloudinary.generateVideoUrl(`${publicId.value}.jpg`, { ...props.options, quality: 'auto:eco', thumbnail: true })
      : '';
  } else {
    return videoPoster?.value?.url;
  }
});
const videoUrl = computed<string | undefined>(() =>
  publicId.value
    ? cloudinary.generateVideoUrl(publicId.value, { ...props.options, quality: props?.options?.quality ?? 'auto:best' })
    : undefined
);
const isBlackBg = computed<boolean>(
  () => props.background_color?.color === '#1a1a1a' || props.background_color?.color === '#000000'
);
const videoFormats = computed(() => {
  const obj: { [index: string]: File } = {};
  const videoFiles = props.cms_asset?.assetConnection?.edges || [];
  if (!videoFiles.length) {
    return null;
  }
  videoFiles.forEach((edge) => {
    const key = edge?.node?.content_type;
    const value = edge?.node;
    obj[key] = value;
  });
  return obj;
});
const videoClasses = computed(() => {
  const defaults: { [key: string]: boolean } = {
    video: true,
  };
  props.videoClassesOverride.forEach((cls) => {
    if (typeof cls === 'string') {
      defaults[cls] = true;
    } else {
      Object.keys(cls).forEach((key) => {
        defaults[key] = cls[key];
      });
    }
  });
  return defaults;
});
const videoContainerClasses = computed(() => {
  const defaults: { [key: string]: boolean } = {};
  props.videoContainerClasses.forEach((cls) => {
    if (typeof cls === 'string') {
      defaults[cls] = true;
    } else {
      Object.keys(cls).forEach((key) => {
        defaults[key] = cls[key];
      });
    }
  });
  return defaults;
});
const videoPoster = computed(() => props.cms_asset?.posterConnection?.edges?.[0]?.node);
const videoObject = computed(() => {
  return {
    formats: videoFormats.value,
    poster: {
      format: videoPoster?.value?.content_type,
      url: videoPoster?.value?.url,
    },
  };
});
const videoType = videoFormats.value && videoPoster.value?.url ? 'cms' : 'cloudinary';
const videoPlayStyle = computed(() => {
  if (props.display_as_gif) {
    return {
      autoplay: 'true',
      loop: 'true',
      muted: props.muted,
      playsinline: 'true',
      controls: !props.muted,
    } as VideoHTMLAttributes;
  }
  const attrs: VideoHTMLAttributes = {
    controls: 'true',
    playsinline: 'true',
  };
  if (videoPoster.value?.url) {
    attrs.poster = videoPoster.value?.url;
  }
  return attrs;
});

const width = computed(() => props.options?.width);
const height = computed(() => props.options?.height);

// function videoClick() {
//   if (playButton?.value) {
//     playButton.value.style.display = 'none';
//   }
// }

// function playButtonClick() {
//   if (videoRef?.value?.paused && playButton?.value) {
//     videoRef.value.play();
//     playButton.value.style.display = 'none';
//   }
// }
function handleIntersect(entry: IntersectionObserverEntry) {
  const video = videoRef.value;
  if (!video) {
    return;
  }
  if (entry.isIntersecting) {
    if (props.display_as_gif) {
      video.play();
    }
  } else {
    if (props.pause_on_scrollout) {
      video.pause();
    }

    if (props.reset_on_scrollout) {
      video.pause();
      video.currentTime = 0;
    }
  }
}

function handlePlay() {
  isPlaying.value = true;
}

function handlePause() {
  isPlaying.value = false;
}

let observer: IntersectionObserver | null = null;

onMounted(() => {
  const video = videoRef.value;

  if (!video) {
    return;
  }

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => handleIntersect(entry));
  });

  observer.observe(video);

  video.addEventListener('play', handlePlay);

  video.addEventListener('pause', handlePause);
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
  const video = videoRef.value;
  if (video) {
    // Remove event listeners
    video.removeEventListener('play', handlePlay);
    video.removeEventListener('pause', handlePause);
  }
});
</script>

<style lang="scss" scoped>
.video-wrapper {
  display: flex;
  text-align: center;

  .video-container {
    position: relative;
    width: 100%;
    section:not(.section-wrapper--pdp-images) & {
      .product-page-wrapper & {
        padding-top: 1rem;
        @include local-mixins.desktop {
          padding: 1.5rem 6rem 3.5rem 6rem;
        }
      }
    }
    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 70px;
      background-color: $color-primary-500;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      z-index: 1;
      @include local-mixins.desktop {
        width: 104px;
        height: 104px;
      }
      &:hover {
        background-color: $color-primary-600;
      }
      &:focus {
        outline: none;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 22px solid white;
        @include local-mixins.tablet_and_mobile {
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 12px solid white;
        }
      }
    }
  }

  .video {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .video-content-wrapper {
    max-width: 768px;
    &.black-bg {
      color: $color-neutral-white;
    }
  }
}
</style>
